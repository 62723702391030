<template>
  <div>
    <v-app-bar flat color="white">
      <div class="pa-2">
        <shop-dialog-component
          ref="refDialogComponent"
          :users="userList"
          @updated="updated"
        ></shop-dialog-component>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import shopDialogComponent from "./component/shopDialogComponent.vue";

export default {
  components: {
    shopDialogComponent,
  },
  props: ["users"],
  setup(props, ctx) {
    const refDialogComponent = ref(null);
    const userList = ref(props.users);

    // app-barにあるコンポーネント呼び出し
    const action = (params) => {
      switch (params.action) {
        case "edit":
          refDialogComponent.value.editDialog(params.value);
          break;
      }
    };

    const updated = (shop_data) => {
      ctx.emit("updated", shop_data);
    };

    return {
      userList,
      refDialogComponent,
      action,
      updated,
    };
  },
};
</script>
