<template>
  <div>
    <app-bar ref="refAppBar" :users="users" @updated="updated"></app-bar>
    <v-card elevation="0">
      <v-card-title>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12" sm="3">
              <v-select
                :items="users"
                v-model="condition.user_id"
                label="ユーザー名選択"
                @change="changeCondition"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="valid_contract_status"
                v-model="condition.valid_contract"
                label="ステータス"
                @change="changeCondition"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="（キーワード検索）"
                single-line
                hide-details
                clearable
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [100, 200, 400, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <v-icon class="pa-1" @click="editItem(item)"> mdi-pencil </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.contract="{ item }">
          <v-icon class="pa-1" @click="contractItem(item)">
            mdi-file-sign
          </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.shop_site="{ item }">
          <v-icon class="pa-1" @click="shopSiteItem(item)"> mdi-web </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.valid_contract="{ item }">
          <div class="pa-1">
            <v-chip
              v-if="item.valid_contract"
              class="ma-2"
              color="primary"
              label
              small
            >
              契約中
            </v-chip>
            <v-chip v-else class="ma-2" label small> 契約なし </v-chip>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="{ item }">
          <v-icon class="pa-1" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data> 表示するデータがありません </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import appBar from "./appBar.vue";
import router from "@/router/index";

export default defineComponent({
  components: {
    appBar,
  },
  setup(_, ctx) {
    const usersRepository = repositoryFactory.get("users");
    const shopsRepository = repositoryFactory.get("shops");
    const refAppBar = ref();
    const state = reactive({
      users: [],
      search: "",
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "登録ユーザー名",
          value: "user_name",
          sortable: true,
          class: "td_name",
        },
        {
          text: "店舗名",
          value: "name",
          sortable: true,
          class: "td_loginid",
        },
        {
          text: "媒体情報",
          value: "shop_site",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "契約情報",
          value: "contract",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "ステータス",
          value: "valid_contract",
          sortable: false,
          align: "center",
        },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
      desserts: [], // DataTablesレコード格納用
      condition: {
        user_id: null,
        valid_contract: null,
      },
    });

    const valid_contract_status = [
      { value: null, text: "未選択" },
      { value: true, text: "契約中" },
      { value: false, text: "契約なし" },
    ];

    const editItem = (item) => {
      // ダイアログ開く
      console.log("item", item);
      refAppBar.value.action({
        action: "edit",
        value: item.id,
      });
    };

    // データ削除
    const deleteItem = async (item) => {
      if (window.confirm("削除してよろしいですか？")) {
        store.dispatch("loadingIcon/showIcon"); // ローディング表示

        // 有効な契約が残っていないかチェック(未来の分も含めて)

        // 削除処理
        await shopsRepository
          .admin_delete(item.id)
          .then((response) => {
            if (response.data) {
              // データ削除OK
              alert(`「${item.name}」が削除されました。`);
            }
          })
          .catch((error) => {
            throw (
              "ERROR:admin/shop/index.vue/deleteItem shopsRepository.admin_delete (" +
              error +
              ")"
            );
          });

        init();

        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
      }
    };

    // 初期化処理(同期処理)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      state.users.splice(0);
      await usersRepository
        .admin_list()
        .then((response) => {
          if (response.data) {
            state.users.push({ value: null, text: "未選択" });
            Object.keys(response.data).forEach(function (key) {
              state.users.push({
                value: response.data[key].id,
                text: response.data[key].name,
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:admin/shop/index.vue/deleteItem usersRepository.admin_list (" +
            error +
            ")"
          );
        });

      state.desserts.splice(0);
      await shopsRepository
        .admin_list(state.condition)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.desserts.push(response.data[key]);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:admin/shop/index.vue/deleteItem shopsRepository.admin_list (" +
            error +
            ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    const updated = (user_data) => {
      init();
    };

    const changeCondition = () => {
      init();
    };

    // 契約情報
    const contractItem = async (item) => {
      // 契約画面に遷移
      await router.push({
        name: "admin-contract-edit",
        params: {
          shop_id: item.id,
        },
      });
    };

    // 媒体情報
    const shopSiteItem = async (item) => {
      // 契約画面に遷移
      await router.push({
        name: "admin-setting-synchro",
        params: {
          shop_id: item.id,
          user_name: item.user_name,
          shop_name: item.name,
        },
      });
    };

    // 返却オブジェクト定義
    return {
      valid_contract_status,
      refAppBar,
      setting,
      ...toRefs(state),
      editItem,
      deleteItem,
      init,
      updated,
      changeCondition,
      contractItem,
      shopSiteItem,
    };
  },
});
</script>
<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
</style>
