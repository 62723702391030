<template>
  <div>
    <v-btn class="info font-weight-bold" @click="showDialog"
      ><v-icon left>mdi-plus-box</v-icon
      ><span class="d-none d-sm-flex">新規登録</span
      ><span class="d-sm-none">新規</span></v-btn
    >
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="100%"
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">店舗設定</span>
        </v-card-title>
        <v-card-text class="scrollable-content">
          <input type="hidden" v-model="item.id" />
          <ValidationObserver ref="observer">
            <h3 id="title_head">基本情報</h3>
            <div class="mt-2 mb-2">店舗に関する情報を設定して下さい。</div>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="userList"
                    v-model="item.user_id"
                    label="ユーザー名選択"
                    hint="ユーザー名を入力してください"
                    persistent-hint
                    outlined
                    :success="item.user_id == null ? false : true"
                    :rules="[(v) => !!v || 'ユーザー名を選択してください']"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="店舗名"
                    rules="required|max:255"
                  >
                    <v-text-field
                      v-model="item.name"
                      name="name"
                      :error-messages="errors"
                      :success="valid"
                      label="店舗名"
                      hint="店舗名を入力してください"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="並び順"
                    rules="required|max:9999"
                  >
                    <v-text-field
                      v-model="item.sort"
                      name="sort"
                      :error-messages="errors"
                      :success="valid"
                      label="並び順"
                      hint="並び順を入力して下さい"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { set, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";

export default defineComponent({
  props: ["users"],
  setup(props, ctx) {
    const shopsRepository = repositoryFactory.get("shops");
    const OpenRepository = repositoryFactory.get("open");
    const ShopApiKeysRepository = repositoryFactory.get("shopApiKeys");
    const state = reactive({
      userList: props.users,
      dialog: false, // ダイアログ表示用
      item: {
        id: 0,
        user_id: 0,
        name: "",
        sort: 9999,
        valid_contract: false,
        valid_option: [],
      },
    });

    // 新規作成
    const showDialog = async () => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // ダイアログ開く
      state.dialog = true;

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    const clearDialog = async () => {
      // 各種項目初期化
      state.item.id = 0;
      state.item.user_id = 0;
      state.item.name = "";
      state.item.sort = 9999;
      state.item.valid_contract = false;
      state.item.valid_option = [];
    };

    const editDialog = async (shopId) => {
      console.log("editDialog shopId:" + shopId);
      await shopsRepository
        .admin_get(shopId)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            state.item.id = response.data.id;
            state.item.user_id = response.data.user_id;
            state.item.name = response.data.name;
            state.item.sort = response.data.sort;
            state.item.valid_contract = response.data.valid_contract;
            state.item.valid_option = response.data.valid_option;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:admin/shop/component/shopdialogComponent.vue/editDialog usersRepository.admin_get (" +
            error +
            ")"
          );
        });
      state.dialog = true;
    };

    // 保存処理
    const onSubmit = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.observer.validate();

      if (!isValid) {
        // エラー処理
        return;
      } else {
        if (!window.confirm("保存してよろしいですか？")) {
          return false;
        }
        // 同名チェック
        let params = {
          id: state.item.id,
          user_id: state.item.user_id,
          name: state.item.name,
        };
        const check_result = await shopsRepository
          .admin_check(params)
          .then((response) => {
            if (response.data) {
              return response.data;
            }
          })
          .catch((error) => {
            throw (
              "ERROR:shopDialogComponent.vue/onSubmit shopsRepository.admin_check (" +
              error +
              ")"
            );
          });

        if (check_result.result == false) {
          alert(check_result.message + "\n" + "保存できません。");
          return false;
        }

        // ローディング表示
        store.dispatch("loadingIcon/showIcon");

        // 保存処理
        params = {
          id: state.item.id,
          name: state.item.name,
          user_id: state.item.user_id,
          sort: state.item.sort,
          is_active: true,
        };

        // 新規か更新か
        if (state.item.id == 0) {
          // 新規
          await shopsRepository
            .admin_create(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                state.item.id = response.data.id; // idをセット
                ctx.refs.observer.reset(); // 入力エラー表示クリア
              }
            })
            .catch((error) => {
              throw (
                "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
                error +
                ")"
              );
            });
        } else {
          // 更新
          await shopsRepository
            .admin_update(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                ctx.refs.observer.reset(); // 入力エラー表示クリア
              }
            })
            .catch((error) => {
              throw (
                "ERROR:dialogComponent.vue/onSubmit usersRepository.update (" +
                error +
                ")"
              );
            });
        }

        // ローディング非表示
        store.dispatch("loadingIcon/hideIcon");

        cancelDialog();

        ctx.emit("updated", params);
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      state.dialog = false;
      ctx.refs.observer.reset(); // 入力エラー表示クリア
      clearDialog();
    };

    const cancelCastDialog = () => {
      state.castDialog = false;
    };

    // 初期化
    const init = async () => {
      store.dispatch("loadingIcon/showIcon");
      clearDialog();
      store.dispatch("loadingIcon/hideIcon");
    };

    init();

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      editDialog,
      onSubmit,
      cancelDialog,
      cancelCastDialog,
      showDialog,
    };
  },
});
</script>

<style scoped></style>
